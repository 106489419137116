<template>
  <el-card>
<!--    <el-row :gutter="20" class="header">-->
<!--      <el-col :span="7">-->
<!--        <el-input placeholder="请输入咨询内容..." clearable v-model="queryForm.query"></el-input>-->
<!--      </el-col>-->
<!--      <el-button type="primary" :icon="Search" @click="initViewsList">搜索</el-button>-->
<!--    </el-row>-->

    <el-table :data="tableData" height="550" stripe style="width: 100%">

      <el-table-column prop="id" label="id" width="100" fixed/>

      <el-table-column prop="title" label="标题" width="150" />

       <el-table-column prop="opinionContant" label="建议内容" width="300" />

       <el-table-column prop="name" label="建议人姓名" width="150" />

      <el-table-column prop="telephone" label="联系电话" width="200" />

      <el-table-column prop="createTime" label="发布时间" width="200" />

<!--      <el-table-column prop="status" label="状态" width="100" />-->

      <el-table-column prop="status" label="是否审核" width="100" >
        <template v-slot="scope">
          <el-tag size="default" :type="scope.row.type_status">{{ scope.row.text_status }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="reply" label="回复" width="200" />

      <el-table-column prop="action" label="操作" width="150" fixed="right">
        <template v-slot="scope">
          <el-button type="success" :icon="Edit"   @click="handleDialogValue(scope.row)">审核</el-button>
<!--          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row.id)">删除</el-button>-->
        </template>
      </el-table-column>

    </el-table>


    <!--  编辑资讯对话框     -->
    <el-dialog title="回复意见" width="25%" center   v-model:visible="news_dialog_Visible" :model-value="news_dialog_Visible" @close="news_dialog_Visible = false">
      <!--  表单    -->
      <el-form ref="newsFormRef" :model="newsForm" label-width="100px">

        <el-form-item label="id">
          <el-input disabled v-model="newsForm.id"></el-input>
        </el-form-item>

        <el-form-item label="回复">
          <el-input  type="textarea"  v-model="newsForm.reply"></el-input>
        </el-form-item>

        <el-form-item label="处理时间">
          <el-input  v-model="newsForm.updateTime"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="news_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="submit_edit">确 定</el-button>
        </el-form-item>

      </el-form>

<!--      <template v-slot:footer class="dialog-footer">-->
<!--        <el-button @click="news_dialog_Visible = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="export1">确 定</el-button>-->
<!--      </template>-->
    </el-dialog>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>

  <Dialog v-model="dialogVisible" :viewDetailData="viewDetailData"/>

</template>

<script setup>

import {Search,Edit,Delete } from '@element-plus/icons-vue'
import { ref } from 'vue'
import axios, { get2 } from "@/util/axios";
import {getBaseUrl} from '@/util/axios'
import {getServerUrl} from "@/config/sys";
import Dialog from './components/dialog'

import {ElMessageBox,ElMessage} from 'element-plus'
import { createLogger } from "vuex";

const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10,
  userId:111,


})

const total=ref(0)


const tableData=ref([
  {
    text_status:'',
    type_status:''

  }
])

const id=ref(-1)
const viewDetailData=ref({})


const requestBody=ref({
  // name:'',
  // telephone:""
  // status:'',
})

const initViewsList=async()=>{
  // console.log('xxx')
  console.log('xxx')
  console.log('反馈意见列表')
  const res=await axios.get("opinion/list",requestBody.value);

  console.log('反馈意见列表',res)

  // const res=await axios.get("views/list");
  // console.log('资讯列表',res)

  tableData.value=res.data.data.opinionList;
  total.value=res.data.total;

  for (let i=0; i<res.data.data.opinionList.length; i++){

    if(tableData.value[i].status==1){
      tableData.value[i].text_status='已审核'
      tableData.value[i].type_status='success'
    }
    else{
      tableData.value[i].text_status='未审核'
      tableData.value[i].type_status='danger'
    }



  }

//   获取baseUrl
  uploadUrl.value = getBaseUrl() + "views/uploadImage", // 上传的图片服务器地址
  console.log('图片上传接口为:',uploadUrl.value)

  // initViewsList111()
}

initViewsList();




// const initViewsList111=async()=>{
//   console.log('xxx')
//   console.log('反馈意见列表')
//   const res=await axios.get("opinion/list",requestBody.value);
//
//   console.log('反馈意见列表',res)
//
//   // tableData.value=res.data.data.viewsList;
//   // total.value=res.data.total;
// }





const dialogVisible=ref(false)



const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initViewsList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initViewsList();
}


const handleDialogValue = (row) => {
  // id.value=row.id;
  viewDetailData.value=row;
  // dialogVisible.value=true;
  console.log("row为：",row)
  console.log("row.id为：",row.id)
  newsForm.value.id = row.id
  news_dialog_Visible.value = true
}

const handleDelete = (id) => {

  ElMessageBox.confirm(
    '您确定要删除这条记录吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {
      console.log("id="+id)
      let res=await axios.del("views/removeById?id="+id);
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '删除成功！',
        });
        initViewsList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.msg,
        });
      }

    })
    .catch(() => {

    })
}

const handleOrderStatus = (id,status) => {
  ElMessageBox.confirm(
    '您确定要更新这个订单状态吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {

      let res=await axios.post("admin/order/updateStatus",{id:id,status:status});
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '执行成功！',
        });
        initViewsList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.msg,
        });
      }

    })
    .catch(() => {

    })
}


// 定义资讯表单
const news_dialog_Visible=ref(false)

const newsForm=ref({
  id:'',
  reply:'',
  updateTime:'',
  status:''
})
// 提交意见回复
const submit_edit=async()=>{
  // console.log('xxx')
  getCurrentTime()

  newsForm.value.status = 1
  const res=await axios.post("opinion/update",newsForm.value);
  console.log('提交意见回复',res)

  if(res.data.code==0){
    ElMessage.success("提交成功！");
    news_dialog_Visible.value = false
    console.log('提交成功！')

  }else{
    ElMessage.error("提交失败！请重试");
    news_dialog_Visible.value = false
    console.log('提交失败！请重试')
  }

  initViewsList();

}

// 定义资讯表单
const extraimageUrl=ref('')
const uploadUrl=ref('')

// 图片上传成功钩子函数
const handleAvatarSuccess = (response, uploadFile) => {
  console.log('response:',response)
  console.log('uploadFile:',uploadFile)
  newsForm.value.imageUrl = response.data.src
}





const gettime=ref('')
const getCurrentTime= () =>{
  //获取当前时间并打印
  // let _this = this;
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth()+1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
  let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
  // _this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
  // console.log(_this.gettime)
  gettime.value = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
  console.log(gettime.value)
  // let strArray = gettime.value.split(' ')
  // console.log(strArray)
  // newsForm.value.updateTime = strArray[0]

  let str = gettime.value.replaceAll("/","-")
  console.log("str为:",str)
  newsForm.value.updateTime = str
  console.log("newsForm.value.updateTime为:",newsForm.value.updateTime)

}


</script>

<style lang="scss" scoped>

.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}


</style>
